<template>
  <v-list-item
      link
      :title="$t('menus.calendar')"
      :to="{ name: 'Agenda' }"
      color="white"
  >
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-calendar</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">{{ $t("menus.calendar") }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuCalendar"
}
</script>

<style scoped>

</style>